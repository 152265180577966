<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
    mixins: [clickaway],
    props: {
        field: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            focus: false,
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
        filtered() {
            return this.field.list
                ? this.field.list.filter((list) => {
                      return list.name.toLowerCase().includes(this.search.toLowerCase())
                  })
                : []
        },
    },
    watch: {
        search: function (newValue) {
            let index = this.field.list.findIndex((x) => {
                return x.name.toLowerCase() === newValue.toLowerCase()
            })
            if (index > -1) {
                this.selectOption(this.field.list[index].value)
            }
        },
    },
    methods: {
        openDropdown() {
            this.focus = true
        },
        closeDropdown() {
            if (this.focus) {
                this.focus = false
            }
        },
        selectOption(newValue) {
            this.closeDropdown()
            this.search = ''
            this.$refs[this.field.name].value = newValue
            this.$refs[this.field.name].dispatchEvent(new InputEvent('input', { bubbles: true }))
        },
    },
}
</script>

<template>
    <div
        v-on-clickaway="closeDropdown"
        class="select"
        :class="[$style.wrapper, focus ? 'hasFocus' : '', field.value ? $style.hasValue : '']"
        @click.self="openDropdown"
    >
        <BaseLabel :name="field.name" :label="field.label" />
        <select
            :id="field.name"
            :ref="field.name"
            :name="field.name"
            class="input"
            :class="$style.select"
            required
            v-on="listeners"
        >
            <option selected disabled value="" />
            <option v-for="(list, key) in field.list" :key="key" :value="list.value" :selected="list.value === value">
                {{ list.name }}
            </option>
        </select>
        <div v-if="focus" :class="$style.searchable" class="searchable">
            <input
                v-model="search"
                :class="$style.searchableInput"
                type="search"
                placeholder="Rechercher"
                autocorrect="off"
                autocomplete="off"
                spellcheck="false"
            />
            <ul v-if="filtered.length" :class="$style.searchableResults">
                <li v-for="(list, key) in filtered" :key="key" @click="selectOption(list.value)" v-text="list.name" />
            </ul>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: relative;
    flex: 68%;
    cursor: pointer;
    .select {
        width: 100%;
        padding-right: 50px;
        pointer-events: none;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBmaWxsPSIjNDE0NjYwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjY2IDEuODg5YTEuMDU4IDEuMDU4IDAgMCAwIDAtMS41NTYgMS4xMTYgMS4xMTYgMCAwIDAtMS41OTIgMEw1IDMuMzMzbC0zLjA2OC0zYTEuMTE2IDEuMTE2IDAgMCAwLTEuNTkxIDBDLjExNC41NTYgMCAuODkgMCAxLjExMWMwIC4zMzMuMTE0LjU1Ni4zNC43NzhsMy44NjUgMy43NzhhMS4xMTYgMS4xMTYgMCAwIDAgMS41OSAwTDkuNjYgMS44ODl6Ii8+PC9zdmc+');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 10px);
        background-position-y: center;
        background-size: 10px 6px;
        appearance: none;
        &.dropdownOpen {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBmaWxsPSIjN2I3MmZjIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjY2IDEuODg5YTEuMDU4IDEuMDU4IDAgMCAwIDAtMS41NTYgMS4xMTYgMS4xMTYgMCAwIDAtMS41OTIgMEw1IDMuMzMzbC0zLjA2OC0zYTEuMTE2IDEuMTE2IDAgMCAwLTEuNTkxIDBDLjExNC41NTYgMCAuODkgMCAxLjExMWMwIC4zMzMuMTE0LjU1Ni4zNC43NzhsMy44NjUgMy43NzhhMS4xMTYgMS4xMTYgMCAwIDAgMS41OSAwTDkuNjYgMS44ODl6Ii8+PC9zdmc+');
        }
    }
}
.hasValue select {
    color: #32325d;
}
.searchable {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    padding-bottom: 0;
    margin-bottom: 30px;
    overflow: hidden;
    background: $color-background-dark;
    border: 1px solid $color-link;
    border-radius: 4px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transition: all 0.08s ease-out;
    transition-delay: 0.1s;
    transition-duration: 0.12s;
    transform-origin: 100% -50px;
}
.searchableInput {
    width: 100%;
    height: 47px;
    padding: 0 12px;
    font-weight: 400;
    text-align: center;
    border: 0;
    background: $color-background-input;
    border-bottom: 1px solid $color-background-dark;
    outline: none;
    &::placeholder {
        color: rgba(#82879e, 0.5);
    }
    color: currentColor;
}
.searchableResults {
    position: relative;
    max-height: 215px;
    padding: 0;
    overflow-y: auto;
    -webkit-tap-highlight-color: transparent;

    li {
        @include unselectable;

        padding: 8px 12px;
        font-weight: 300;
        line-height: 26px;
        cursor: pointer;
        &:not(:first-child) {
            border-top: 1px solid $color-background-dark-soft;
        }
        &:hover {
            background: $color-background-dark-soft;
        }
    }
}
</style>
